import resolveConfig from "tailwindcss/resolveConfig";
// @ts-ignore
import tailwindConfig from "../../tailwind.config.js";

const getBreakpointString = (
  breakpoint: "xs" | "sm" | "md" | "lg" | "xl" | "2xl"
) => {
  const {
    theme: { screens },
  } = resolveConfig(tailwindConfig);
  switch (breakpoint) {
    case "xs":
      return `(min-width: 0px) and (max-width: ${screens.sm})`;
    case "sm":
      return `(min-width: ${screens.sm}) and (max-width: ${screens.md})`;
    case "md":
      return `(min-width: ${screens.md}) and (max-width: ${screens.lg})`;
    case "lg":
      return `(min-width: ${screens.lg}) and (max-width: ${screens.xl}px)`;
    case "xl":
      return `(min-width: ${screens.xl}) and (max-width: ${screens["2xl"]})`;
    case "2xl":
      return `(min-width: ${screens["2xl"]}) and (max-width: 99999px)`;
    default:
      return `0`;
  }
};

export { getBreakpointString };
