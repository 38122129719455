const plugin = require("tailwindcss/plugin");
const defaultTheme = require("tailwindcss/defaultTheme");
const colors = require("tailwindcss/colors");

module.exports = {
  mode: "jit",
  content: ["public/index.html", "src/**/*.tsx", "src/css/tailwind.css"],
  theme: {
    fontSize: {
      xs: "0.75rem",
      sm: "0.875rem",
      base: "1rem",
      lg: "1.125rem",
      xl: "1.25rem",
      "1.5xl": "1.375rem",
      "2xl": "1.5rem",
      "3xl": "1.875rem",
      "4xl": "2.25rem",
      "4.25xl": "2.5rem",
      "4.5xl": "2.75rem",
      "5xl": "3rem",
      "5.5xl": "3.5rem",
      "6xl": "4rem",
      "6.5xl": "5rem",
      "7xl": "6rem",
    },
    extend: {
      fontFamily: {
        sans: ["Rubik", ...defaultTheme.fontFamily.sans],
        headline: ["Rubik", ...defaultTheme.fontFamily.sans],
      },
      fontSize: {
        xxs: "0.75rem",
      },
      fontWeight: {
        DEFAULT: 400,
        bold: 600,
        black: 900,
      },
      lineHeight: {
        12: "3rem",
        14: "3.25rem",
        negative: "0.875",
      },
      letterSpacing: {
        widest: ".175em",
      },
      spacing: {
        14: "3.5rem",
        128: "32rem",
        "3/4": "75%",
      },
      translate: {
        "-1/7": "-14.2857143%", // for the hero image on mobile
      },
      minHeight: {
        92: "24",
        112: "28rem",
        128: "32rem",
        144: "36rem",
        160: "40rem",
        176: "44rem",
        192: "48rem",
        208: "52rem",
        224: "56rem",
      },
      maxHeight: {
        "screen-20": "20vh",
        "screen-40": "40vh",
      },
      height: {
        112: "28rem",
        128: "32rem",
        144: "36rem",
        160: "40rem",
        176: "44rem",
        192: "48rem",
        208: "52rem",
        "screen-60": "60vh",
        "3/5": "60%",
        "4/5": "80%",
      },
      width: {
        "7xl": "80rem",
      },
      minWidth: {
        16: "4rem",
        30: "7.5rem",
      },
      maxWidth: {
        "7xl": "80rem",
      },
      colors: {
        primary: "#344155",
        primarydark: "#0FB881",
        gray: colors.slate,
        green: colors.emerald,
      },
      rotate: {
        "-10": "-10deg",
      },
      scale: {
        "-1": "-1",
      },
      typography: {
        default: {
          css: {
            h1: {
              marginBottom: "0.75em",
            },
            h2: {
              marginTop: "0.75em",
              marginBottom: "0.5em",
            },
            h3: {
              marginTop: "0.75em",
              marginBottom: "0.25em",
            },
            img: {
              marginTop: "0",
              marginBottom: "0",
            },
          },
        },
      },
    },
    container: {
      center: true,
    },
  },
  variants: {
    extend: {
      underline: ["checked"],
    },
  },
  plugins: [
    require("@tailwindcss/aspect-ratio"),
    require("@tailwindcss/forms"),
    require("@tailwindcss/typography"),
    require("tailwind-easing-gradients")({
      variants: ["responsive"],
      // required
      gradients: {
        "navigation-shadow": {
          easing: "ease-in-out",
          steps: 5,
          color: ["rgba(15,23,42,0.75)", "rgba(15,23,42,0)"],
        }, // must be two colors
      },
      // defaults
      alphaDecimals: 5,
      colorMode: "lrgb",
      type: "linear",
      easing: "ease",
      colorStops: 15,
      directions: {
        t: "to top",
        r: "to right",
        b: "to bottom",
        l: "to left",
      },
    }),
    plugin(function ({ addUtilities }) {
      addUtilities(
        {
          ".safe-top": {
            paddingTop: "env(safe-area-inset-top)",
          },
          ".safe-left": {
            paddingLeft: "env(safe-area-inset-left)",
          },
          ".safe-right": {
            paddingRight: "env(safe-area-inset-right)",
          },
          ".safe-bottom": {
            paddingBottom: "env(safe-area-inset-bottom)",
          },
        },
        { variants: ["responsive"] }
      );
    }),
  ],
};
