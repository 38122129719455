import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import SignupForm from "./SignupForm";

export default function SignupFormCard() {
  const { background } = useStaticQuery(graphql`
    query SignupFormCardQuery {
      background: file(
        relativePath: { eq: "images/patterns/pattern-coins.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 782
            quality: 90
            placeholder: BLURRED
            blurredOptions: { width: 200 }
          )
        }
      }
    }
  `);

  const { t } = useTranslation(["sign_up_form", "common"]);

  return (
    <div className="relative overflow-hidden h-96">
      <div className="absolute inset-0 overflow-hidden bg-gradient-to-b from-gray-700 to-gray-900 rounded-xl">
        <GatsbyImage
          image={background.childImageSharp.gatsbyImageData}
          alt="planet background image"
          // loading="eager"
          className="w-full h-full opacity-50"
        />
      </div>
      <div className="absolute inset-0 flex flex-col items-center justify-center px-6 space-y-8">
        <div className="flex flex-col">
          <h2 className="mb-2 text-2xl text-center text-white font-headline xl:text-3xl">
            {t("sign_up_form:title")}
          </h2>
          <span className="text-sm text-center text-white opacity-75 font-headline md:text-base">
            {t("sign_up_form:subtitle")}
          </span>
        </div>
        <div className="flex flex-row justify-center w-full">
          <SignupForm />
        </div>
        <span className="text-xs tracking-wide text-center text-white opacity-75 font-headline">
          {t("common:privacy.description") + " "}
          <br className="sm:hidden" />
          <Link to="/datenschutzerklaerung" className="text-gray-200 underline">
            {t("common:privacy.privacy_policy")}
          </Link>
        </span>
      </div>
    </div>
  );
}
