import { graphql } from "gatsby";
import React from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import Hero from "../components/Hero";
import HowPlan3tWorks from "../components/HowPlan3tWorksCashback";
import PartnersSection from "../components/PartnersSection";
import SecuritySection from "../components/SecuritySection";
import SignupFormCard from "../components/SignupFormCard";
import SocialMediaTeaser from "../components/SocialMediaTeaser";
import { useGlobalState } from "../state/globalState";

const IndexPage = () => {
  const [headerOptions, setHeaderOptions] = useGlobalState("headerOptions");

  React.useEffect(() => {
    setHeaderOptions({
      ...headerOptions,
      colorScheme: "dark",
      hidden: false,
    });
  }, []);

  return (
    <>
      <ParallaxProvider>
        <Hero />
        <PartnersSection />
        <HowPlan3tWorks />
        <SecuritySection />
        <div className="py-12 mx-auto space-y-12 container-5xl">
          <SocialMediaTeaser />
          <SignupFormCard />
        </div>
      </ParallaxProvider>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
