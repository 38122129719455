import classNames from "classnames";
import React from "react";

// DOT COMPONENT

export interface SlideDotProps {
  index: number;
  onClick?: (index: number) => void;
  active?: boolean;
}

const SlideDot: React.FC<SlideDotProps> = ({ index, active, onClick }) => {
  return (
    <button
      onClick={() => {
        onClick && onClick(index);
      }}
      className="w-4 h-4 p-1 group"
    >
      <div
        className={classNames({
          "w-2 h-2 rounded-full bg-gray-200 group-hover:bg-green-300": true,
          "bg-green-300": active,
        })}
      />
    </button>
  );
};

export default SlideDot;
