import { graphql, useStaticQuery } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { useGlobalState } from "../state/globalState";
import DownloadTeaserSmall from "./DownloadTeaserSmall";
import ImageAndText from "./sections/ImageAndText";

const query = graphql`
  query HowPlanetWorksCashbackQuery {
    connect: file(
      relativePath: { eq: "images/app-screenshots/connect-visual.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          placeholder: BLURRED
          blurredOptions: { width: 120 }
        )
      }
    }
    impact: file(
      relativePath: { eq: "images/app-screenshots/impact-visual.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          placeholder: BLURRED
          blurredOptions: { width: 120 }
        )
      }
    }
    donate: file(
      relativePath: { eq: "images/app-screenshots/donate-visual.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          placeholder: BLURRED
          blurredOptions: { width: 120 }
        )
      }
    }
    tips: file(relativePath: { eq: "images/app-screenshots/tips-visual.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          placeholder: BLURRED
          blurredOptions: { width: 120 }
        )
      }
    }
    rewards: file(
      relativePath: { eq: "images/app-screenshots/rewards-visual.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          placeholder: BLURRED
          blurredOptions: { width: 120 }
        )
      }
    }
    cashback: file(
      relativePath: { eq: "images/app-screenshots/cashback-visual.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          placeholder: BLURRED
          blurredOptions: { width: 120 }
        )
      }
    }
  }
`;

export interface HowPlan3tWorksQueryData {
  connect: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  cashback: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  donate: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  rewards: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  impact: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  tips: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
}

export default function HowPlan3tWorks() {
  const { t } = useTranslation(["homepage"]);

  const images: HowPlan3tWorksQueryData = useStaticQuery(query);

  const [utmTags] = useGlobalState("utmTags");

  const [isDownloadPopupOpen, setIsDownloadPopupOpen] = useGlobalState(
    "isDownloadPopupOpen"
  );

  return (
    <div className="flex flex-col py-12 space-y-12 container-5xl">
      <div>
        <h4 className="mb-2 text-sm tracking-widest text-center text-gray-500 uppercase font-headline">
          {t("homepage:how_planet_works.topline")}
        </h4>
        <h1 className="text-2xl text-center font-headline text-primary">
          {t("homepage:how_planet_works.title")}
        </h1>
      </div>
      <ImageAndText
        img={images.connect}
        headline={t("homepage:how_planet_works.section_1.title")}
        body={
          <>
            <p>
              <span>
                {t("homepage:how_planet_works.section_1.paragraph1_1")}{" "}
              </span>
            </p>

            <Link to="/security" className="text-green-500">
              {t("homepage:how_planet_works.section_1.link")}
            </Link>
          </>
        }
      />
      <DownloadTeaserSmall showMoreButton moreButtonLink="#features" />
      <ImageAndText
        id="features"
        img={images.cashback}
        reversed
        headline={t("homepage:how_planet_works.section_2.title")}
        body={
          <>
            <p>{t("homepage:how_planet_works.section_2.paragraph1")}</p>
            <Link to="/partners" className="text-green-500">
              {t("homepage:how_planet_works.section_2.link")}
            </Link>
          </>
        }
      />
      <ImageAndText
        img={images.donate}
        headline={t("homepage:how_planet_works.section_3.title")}
        body={
          <>
            <p>{t("homepage:how_planet_works.section_3.paragraph1")}</p>
          </>
        }
      />
      <ImageAndText
        img={images.rewards}
        reversed
        headline={t("homepage:how_planet_works.section_4.title")}
        body={
          <>
            <p>{t("homepage:how_planet_works.section_4.paragraph1")}</p>
            <Link to="/our-partners" className="text-green-500">
              {t("homepage:how_planet_works.section_4.link")}
            </Link>
          </>
        }
      />
      <ImageAndText
        img={images.impact}
        headline={t("homepage:how_planet_works.section_5.title")}
        body={
          <>
            <p>{t("homepage:how_planet_works.section_5.paragraph1")}</p>
          </>
        }
      />
      <ImageAndText
        img={images.tips}
        reversed
        headline={t("homepage:how_planet_works.section_6.title")}
        body={
          <>
            <p>{t("homepage:how_planet_works.section_6.paragraph1")}</p>
          </>
        }
      />
    </div>
  );
}
