import React from "react";
import { useTranslation } from "react-i18next";
import { useGlobalState } from "../state/globalState";

export interface DownloadTeaserSmallProps {
  showMoreButton?: boolean;
  moreButtonLink?: string;
}

const DownloadTeaserSmall: React.FC<DownloadTeaserSmallProps> = ({
  showMoreButton = false,
  moreButtonLink,
}) => {
  const { t } = useTranslation("teaser");

  const [utmTags] = useGlobalState("utmTags");

  const [isSignupPopupOpen, setIsSignupPopupOpen] =
    useGlobalState("isSignupPopupOpen");

  const handleOpenSignupPopup = React.useCallback(() => {
    setIsSignupPopupOpen(true);
  }, []);

  const [isDownloadPopupOpen, setIsDownloadPopupOpen] = useGlobalState(
    "isDownloadPopupOpen"
  );

  return (
    <div className="flex flex-col items-center p-6 space-y-6 bg-gray-100 md:space-y-0 md:space-x-6 md:flex-row rounded-xl">
      <h2 className="flex-grow text-xl text-primary font-headline">
        {t("teaser:download.title")}
      </h2>
      <div className="flex space-x-4 shrink-0">
        {showMoreButton && (
          <a href={moreButtonLink} className="block button-green-outline !px-4">
            {t("teaser:download.cta_more")}
          </a>
        )}
        <a
          href={`/download${utmTags && utmTags !== "" ? "?" : ""}${utmTags}`}
          className="block md:hidden button-green-filled !px-4"
        >
          {t("teaser:download.cta_download")}
        </a>
        <button
          className="!hidden md:!block button-green-filled !px-4"
          onClick={() => {
            setIsDownloadPopupOpen(true);
          }}
        >
          {t("teaser:download.cta_download")}
        </button>
      </div>
    </div>
  );
};

export default DownloadTeaserSmall;
