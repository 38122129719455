import classnames from "classnames";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { Link } from "gatsby-plugin-react-i18next";
import React from "react";

export interface PartnerProps {
  name: string;
  url: string;
  image: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  className?: string;
}

const PartnerSliderItem: React.FC<PartnerProps> = ({
  name,
  url,
  image,
  className,
}) => {
  return (
    <Link
      to={url}
      className={classnames(
        "flex flex-col items-center space-y-2 p-4",
        className
      )}
    >
      <div className="w-20 h-20 p-1 overflow-hidden bg-white border rounded-full plan3t__clip_circle">
        {image && (
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt={name}
            // loading="eager"
            objectFit="contain"
            objectPosition="50% 50%"
            className="w-full h-full overflow-hidden rounded-full plan3t__clip_circle"
          />
        )}
      </div>
      <span className="max-w-full tracking-wide text-center break-words font-headline">
        {name}
      </span>
    </Link>
  );
};

export default PartnerSliderItem;
