// import KeenSlider, { useKeenSlider } from "keen-slider/react";
import { useKeenSlider } from "keen-slider/react";
import React from "react";
import SlideDot from "./PartnerSliderDot";

const autoPlayInterval = 3000;

export interface PartnerSliderProps {
  options: any;
  className: string;
}

const PartnerSlider: React.FC<PartnerSliderProps> = ({
  options,
  children,
  className,
}) => {
  const [loaded, setLoaded] = React.useState(false);
  const [pause, setPause] = React.useState(false);
  const timer = React.useRef<NodeJS.Timeout | null>(null);

  const [slideNum, setSlideNum] = React.useState(0);

  const total = React.Children.count(children);
  const numSlides = Math.ceil(total);

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    ...options,
    slideChanged: (s) => {
      setSlideNum(s.track.details.rel);
      slideDotsToIndex(s.track.details.rel);
    },
    dragStarted: () => {
      setPause(true);
    },
    dragEnded: () => {
      setPause(false);
    },
    created: () => {
      setLoaded(true);
    },
  });

  const [sliderDotsRef, sliderDots] = useKeenSlider<HTMLDivElement>({
    slides: {
      perView: 5,
      origin: "center",
    },
    loop: true,
  });

  React.useEffect(() => {
    if (!slider.current) {
      return;
    }

    const el = slider.current.container;

    const over = () => setPause(true);
    const out = () => setPause(false);

    el.addEventListener("mouseover", over);
    el.addEventListener("mouseout", out);

    return () => {
      slider && slider.current?.destroy();
      el.removeEventListener("mouseover", over);
      el.removeEventListener("mouseout", out);
    };
  }, [sliderRef]);

  React.useEffect(() => {
    if (numSlides < 2) {
      return;
    }
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.current?.next();
      }
    }, autoPlayInterval);

    return () => {
      timer.current !== null && clearInterval(timer.current);
    };
  }, [numSlides, pause, slider]);

  const handleDotClick = React.useCallback(
    (index) => {
      if (slider) {
        slider.current?.moveToIdx(index);
      }
    },
    [slider]
  );

  const slideDotsToIndex = React.useCallback(
    (index) => {
      if (sliderDots) {
        sliderDots.current?.moveToIdx(index);
      }
    },
    [sliderDots]
  );

  return (
    <div className={className}>
      <div className="w-full keen-slider" ref={sliderRef}>
        {React.Children.map(children, (child, i) => (
          <div
            key={i}
            className="flex flex-row justify-center keen-slider__slide partner-slide"
          >
            {child}
          </div>
        ))}
      </div>
      <div className="w-20 mx-auto mt-2">
        <div className="w-full keen-slider" ref={sliderDotsRef}>
          {React.Children.map(children, (_, i) => (
            <div
              key={i}
              className="flex flex-row justify-center keen-slider__slide"
            >
              <SlideDot
                index={i}
                onClick={handleDotClick}
                active={i === slideNum}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PartnerSlider;
