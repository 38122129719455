import classNames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { Link } from "gatsby-plugin-react-i18next";
import React from "react";
import { useTranslation } from "react-i18next";

const query = graphql`
  query SecuritySectionQuery {
    image: file(relativePath: { eq: "images/security/security-visual.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 464
          placeholder: BLURRED
          quality: 90
          blurredOptions: { width: 120 }
        )
      }
    }
  }
`;

export interface SecuritySectionQueryData {
  image: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
}

export interface SecuritySectionProps {
  flipOrder?: boolean;
}

const SecuritySection: React.FC<SecuritySectionProps> = ({ flipOrder }) => {
  const imageData: SecuritySectionQueryData = useStaticQuery(query);
  const { t, ready } = useTranslation("security");

  const bullets: string[] | null = React.useMemo(() => {
    return ready ? t("security:teaser.bullets", { returnObjects: true }) : null;
  }, [ready]);

  return (
    <div className="bg-green-50">
      <div className="grid grid-cols-2 gap-12 py-12 container-5xl">
        <div
          className={classNames({
            "flex items-center order-1 col-span-2 md:col-span-1": true,
            "md:order-1": !flipOrder,
            "md:order-2": flipOrder,
          })}
        >
          <GatsbyImage
            className="w-full max-w-sm m-auto md:max-w-md"
            image={imageData.image.childImageSharp.gatsbyImageData}
            alt="Security"
            // loading="eager"
          />
        </div>
        <div
          className={classNames({
            "flex flex-col justify-center order-2 w-full max-w-md col-span-2 m-auto space-y-4 text-center md:text-left md:col-span-1 ":
              true,
            "md:order-1": flipOrder,
            "md:order-2": !flipOrder,
          })}
        >
          <h2 className="text-xl text-primary font-headline">
            {t("security:teaser.title")}
          </h2>
          <div className="flex flex-col space-y-3 font-normal leading-relaxed tracking-wide text-gray-500">
            {t("security:teaser.copytext")}
          </div>
          {bullets && (
            <ul className="space-y-3">
              {bullets.map((b) => (
                <li
                  key={b}
                  className="flex flex-row items-center space-x-2 text-green-500"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="fill-current"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.9962 6.63699C16.8666 4.01164 14.6775 1.9591 12.0494 1.99861L12 1.99968L11.9506 1.99861C9.29594 1.9587 7.08921 4.05332 7.00055 6.71672L7 6.73335V7.99995L6 7.99998L5.96555 8.00028C4.87687 8.01866 4 8.90692 4 9.99998V20L4.00029 20.0344C4.01868 21.1231 4.90694 22 6 22H18L18.0344 21.9997C19.1231 21.9813 20 21.093 20 20V9.99998L19.9997 9.96554C19.9813 8.87686 19.0931 7.99998 18 7.99998L17 7.99995V6.73335L16.9962 6.63699ZM6 10.0001H18V20.0001H6V10.0001ZM12 12.0001C13.1046 12.0001 14 12.8955 14 14.0001C14 14.7403 13.5978 15.3866 13.0001 15.7325L13 17.0001C13 17.5524 12.5523 18.0001 12 18.0001C11.4571 18.0001 11.0152 17.5674 11.0004 17.0281L11 17.0001L11 15.7325C10.4022 15.3867 10 14.7404 10 14.0001C10 12.8955 10.8954 12.0001 12 12.0001ZM12.0783 3.99836C13.6296 3.97479 14.9219 5.18613 14.9986 6.73567L15 6.77124V7.99994H9V6.76894L9.00139 6.73614C9.07785 5.18654 10.37 3.97503 11.9212 3.99835L12 4.00042L12.0783 3.99836Z"
                    />
                  </svg>
                  <span className="leading-snug text-left">{b}</span>
                </li>
              ))}
            </ul>
          )}
          <div className="!mt-8">
            <Link to="/security" className="button-green-filled">
              {t("security:teaser.cta_text")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SecuritySection;
