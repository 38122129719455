import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { getBreakpointString } from "../helpers/getBreakpoint";
import { IPartner } from "../types/gatsby";
import Slider from "./PartnerSlider";
import PartnerSliderItem from "./PartnerSliderItem";

const query = graphql`
  query PartnersSectionQuery {
    partnerData: allPlan3TPartner(
      filter: { id: { ne: "dummy" } }
      sort: { fields: fields___weight }
    ) {
      edges {
        node {
          id
          name
          alternative_id
          logo_localImage {
            id
            childImageSharp {
              gatsbyImageData(
                width: 120
                quality: 80
                placeholder: BLURRED
                blurredOptions: { width: 40 }
              )
            }
          }
          fields {
            categories {
              id
              title
            }
          }
        }
      }
    }
  }
`;

export interface PartnerQueryData {
  partnerData: {
    edges: {
      node: IPartner;
    }[];
  };
}

const PartnersSection = () => {
  const { partnerData }: PartnerQueryData = useStaticQuery(query);
  const { t } = useTranslation(["homepage"]);

  const activePartners = React.useMemo(() => {
    return partnerData.edges.filter(
      ({ node }) => node.fields.categories.length > 0
    );
  }, []);

  return (
    <div className="bg-gray-100">
      <div className="flex flex-col items-stretch p-12 container-7xl ">
        <h2 className="mb-8 text-sm tracking-widest text-center text-gray-500 uppercase font-headline">
          {t("homepage:partners.title")}
        </h2>

        <Slider
          options={{
            slides: {
              perView: 6,
              spacing: 40,
            },
            breakpoints: {
              [getBreakpointString("xs")]: {
                slides: { perView: 2, spacing: 40 },
              },
              [getBreakpointString("sm")]: {
                slides: { perView: 3, spacing: 40 },
              },
              [getBreakpointString("md")]: {
                slides: { perView: 4, spacing: 40 },
              },
              [getBreakpointString("lg")]: {
                slides: { perView: 6, spacing: 40 },
              },
            },
            mode: "snap",
            loop: true,
          }}
          className="mb-8"
        >
          {activePartners.map(
            ({ node: { id, name, logo_localImage, alternative_id } }) => (
              <PartnerSliderItem
                key={id}
                name={name}
                url={`/our-partners/partners/${alternative_id}`}
                image={logo_localImage}
              />
            )
          )}
        </Slider>
      </div>
    </div>
  );
};

export default PartnersSection;
