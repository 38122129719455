import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { Parallax } from "react-scroll-parallax";
import { useGlobalState } from "../state/globalState";
import DownloadButtons from "./DownloadButtons";

const query = graphql`
  query HeroQuery {
    site {
      siteMetadata {
        downloadActive
      }
    }
    heroDevice1: file(
      relativePath: { eq: "images/app-screenshots/hero-ios-1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 512
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 80 }
        )
      }
    }
    heroDevice2: file(
      relativePath: { eq: "images/app-screenshots/hero-ios-2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 512
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 80 }
        )
      }
    }
    background: file(
      relativePath: { eq: "images/hero-images/coins-background.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1600
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 200 }
        )
      }
    }
    foreground: file(
      relativePath: { eq: "images/hero-images/coins-foreground.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1600
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 200 }
        )
      }
    }
  }
`;

export interface HeroQueryData {
  site: {
    siteMetadata: {
      downloadActive: boolean;
    };
  };
  background: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  foreground: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  heroDevice1: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  heroDevice2: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
}

const Hero = () => {
  const { t } = useTranslation(["common", "homepage"]);

  const [isSignupPopupOpen, setIsSignupPopupOpen] =
    useGlobalState("isSignupPopupOpen");

  const [isDownloadPopupOpen, setIsDownloadPopupOpen] = useGlobalState(
    "isDownloadPopupOpen"
  );

  const [utmTags] = useGlobalState("utmTags");

  const handleOpenSignupPopup = React.useCallback(() => {
    setIsSignupPopupOpen(true);
  }, []);

  const {
    background,
    foreground,
    heroDevice1,
    heroDevice2,
    site: {
      siteMetadata: { downloadActive },
    },
  }: HeroQueryData = useStaticQuery(query);

  return (
    <div className="relative w-full h-auto overflow-hidden bg-gray-900 md:h-160 lg:h-208 bg-gradient-to-b from-gray-700 to-gray-900">
      <Parallax
        className="absolute w-full h-full"
        y={[-25, 0]}
        styleInner={{ display: "flex", height: "100%" }}
      >
        <GatsbyImage
          image={background.childImageSharp.gatsbyImageData}
          alt="coins background image"
          // loading="eager"
          className="object-fill mx-auto mt-40 -ml-80 h-144 md:h-full md:mt-32 md:ml-auto"
        />
      </Parallax>
      <Parallax
        className="absolute w-full h-full"
        y={[-10, 0]}
        styleInner={{ display: "flex", height: "100%" }}
      >
        <GatsbyImage
          image={foreground.childImageSharp.gatsbyImageData}
          alt="coins foreground image"
          // loading="eager"
          className="object-fill mx-auto mt-32 -ml-72 md:mt-24 h-128 md:h-full md:ml-auto"
        />
      </Parallax>

      <section className="grid h-full grid-cols-2 !px-0 md:!px-12 container-6xl">
        <div className="z-10 flex flex-col justify-center order-3 w-full col-span-2 px-8 pt-0 pb-12 space-y-4 md:py-0 md:items-start md:space-y-6 md:col-span-1 md:order-1 md:p-0 md:pr-6">
          <h1 className="text-6xl font-black text-white uppercase leading-negative lg:text-7xl font-headline">
            <Trans i18nKey="hero.title">
              Spare
              <span className="font-black text-purple-500">Geld</span>
              <br />
              und <span className="font-black text-amber-500">CO₂</span>
            </Trans>
          </h1>
          <p className="text-xl font-bold leading-snug text-white md:text-lg lg:text-2xl">
            {t("homepage:hero.description")}
          </p>
          {downloadActive ? (
            <div>
              <a
                className="button-green-filled md:hidden"
                href={`/download${
                  utmTags && utmTags !== "" ? "?" : ""
                }${utmTags}`}
              >
                {t("common:download.general")}
              </a>

              <DownloadButtons
                className="hidden md:block"
                buttonClassName="md:h-9 lg:h-12"
                onClick={() => {
                  setIsDownloadPopupOpen(true);
                }}
              />
            </div>
          ) : (
            <>
              <button
                onClick={handleOpenSignupPopup}
                className="w-full px-10 py-3 text-sm font-medium tracking-wide text-white uppercase bg-green-500 rounded-lg md:hidden hover:shadow-md font-headline"
              >
                {t("common:download.general")}
              </button>

              <DownloadButtons onClick={handleOpenSignupPopup} />
            </>
          )}
        </div>

        <div className="flex flex-col justify-center order-1 w-full h-full col-span-2 p-4 pt-24 md:pt-20 sm:px-8 md:px-0 md:col-span-1 md:order-2">
          <div className="relative p-2">
            <Parallax
              className="absolute w-full h-full overflow-visible"
              y={[8, -8]}
              x={[8, -8]}
              styleInner={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <GatsbyImage
                image={heroDevice1.childImageSharp.gatsbyImageData}
                alt="plan3t app screenshot"
                // loading="eager"
                className="h-96 md:h-128"
                objectFit="contain"
              />
            </Parallax>
            <Parallax
              className=""
              y={[16, -16]}
              x={[-8, 8]}
              styleInner={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <GatsbyImage
                image={heroDevice2.childImageSharp.gatsbyImageData}
                alt="plan3t app screenshot"
                // loading="eager"
                className="h-96 md:h-128"
                objectFit="contain"
              />
            </Parallax>
          </div>
        </div>

        {/* <div className="z-10 order-2 col-span-2 md:-mt-12 sm:mt-0 md:order-3">
          <FeaturedOn />
        </div> */}
      </section>
    </div>
  );
};

export default Hero;
